import React, { useState, useMemo } from 'react';
import { ethers } from 'ethers';
import abi from '../abi/cnp.json'
import { useLoading } from '../contexts/LoadingContext';

const config = {
  contractAddress: '0x138a5c693279b6cd82f48d4bef563251bc15adce',
}

const NFTLockScreen: React.FC = () => {
  const [tokenId, setTokenId] = useState<string>('');
  const [status, setStatus] = useState<string>('');
  const [contract, setContract] = useState<ethers.Contract>();
  const { startLoading, stopLoading } = useLoading();

  const handleLock = () => {
    if (!tokenId) {
      setStatus('Token ID is required');
      return;
    }
    const lock = contract?.getFunction('setBlockedTokens')
    const tokenIds = tokenId.split(',')
    if (lock) {
      startLoading();
      lock(tokenIds).catch((err) => {
        setStatus(err.message)
      }).then((x) => {
        if (x)
          setStatus('NFT Locked Token ID: ' + tokenId)
      }).finally(() => {
        stopLoading();
      })
    }
  };

  const handleUnlock = () => {
    if (!tokenId) {
      setStatus('Token ID is required');
      return;
    }
    setStatus('NFT Unlocked');
    const unlock = contract?.getFunction('unsetBlockedTokens')
    const tokenIds = tokenId.split(',')
    if (unlock) {
      startLoading();
      unlock(tokenIds).catch((err) => {
        setStatus(err.message)
      }).then((x) => {
        if (x)
          setStatus('NFT Unlocked Token ID: ' + tokenId)
      }).finally(() => {
        stopLoading();
      })
    }
  };

  const checkStatus = async () => {
    if (!tokenId) {
      setStatus('Token ID is required');
      return;
    }
    const check = contract?.getFunction('getBlockedToken')
    const tokenIds = tokenId.split(',')
    let statusTmp = ''
    if (check) {
      startLoading();
      Promise.all(tokenIds.map((id) => {
        return check(id).catch((err) => {
          statusTmp += err.message + '\n'
        }).then((x) => {
          if (x === true) {
            statusTmp += 'Status: Locked \nID: ' + id + '\n'
          } else if (x === false) {
            statusTmp += 'Status: Unlocked \nID: ' + id + '\n'
          }
        })
      })).then(() => {
        setStatus(statusTmp)
      }).finally(() => {
        stopLoading();
      })
    }
  };

  useMemo(async () => {
    if (window.ethereum) {
      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const contract = new ethers.Contract(config.contractAddress, abi, signer);
      setContract(contract);
    }
  }, []);

  return (
    <div className="container mx-auto p-4">
      <div className="mb-4">
        <label htmlFor="tokenId" className="block text-gray-700 text-sm font-bold mb-2">
          Token ID:
        </label>
        <input
          type="text"
          id="tokenId"
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          value={tokenId}
          autoComplete='off'
          onChange={(e) => setTokenId(e.target.value)}
        />
      </div>
      <div className="flex space-x-4">
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          onClick={handleLock}
        >
          Lock
        </button>
        <button
          className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          onClick={handleUnlock}
        >
          Unlock
        </button>
        <button
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          onClick={checkStatus}
        >
          Check Status
        </button>
      </div>
      {status && <p className="mt-4 break-all whitespace-pre">{status}</p>}
    </div>
  );
};

export default NFTLockScreen;
