import React, { useState } from 'react';
import './App.css';
import { ethers } from 'ethers';
import NFTLockScreen from './components/NFTLockScreen'; // NFTLockScreen コンポーネントのインポート
import { LoadingProvider } from './contexts/LoadingContext';

const config = {
  networkId: '1',
  chainId: '0x1',
}
const App: React.FC = () => {
  const [walletConnected, setWalletConnected] = useState(false);

  const connectWallet = async () => {
    if (window.ethereum) {
      try {
        const provider = new ethers.BrowserProvider(window.ethereum);
        await provider.send("eth_requestAccounts", []);
        const networkId = await provider.send("net_version", []);
        if (networkId !== config.networkId) {
          alert('Please connect to the Mainnet Network');
          provider.send("wallet_switchEthereumChain", [{ chainId: config.chainId }]);
          return;
        }
        setWalletConnected(true);
      } catch (error) {
        console.error('Error connecting to the wallet:', error);
      }
    } else {
      alert('Ethereum wallet not detected. Please install MetaMask or similar.');
    }
  };

  return (
    <LoadingProvider>
      <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center">
        <header className="text-lg text-gray-700 p-4">
          <h1>CNP Lock Manager</h1>
        </header>
        <main className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          {!walletConnected ? (
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              onClick={connectWallet}
            >
              Connect Wallet
            </button>
          ) : (
            <NFTLockScreen />
          )}
        </main>
      </div>
    </LoadingProvider >
  );
};

export default App;
